.AgGameTile {
  overflow: visible;

  @include supportsHover {
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: $radius;
      pointer-events: none;
      z-index: 1;
      box-shadow: 0 0 0 0 rgba($primaryColor, 0) inset;
      transition: box-shadow 1s ease;
    }

    &:hover {
      &::after {
        transition: box-shadow 0s ease;
        box-shadow: 0 0 0 2px rgba($primaryColor, 1) inset;
      }
    }
  }

  .GameTile__Description {
    bottom: 0;
    padding: $marginSm3 $marginSm2 $marginSm1 $marginSm2;
    font-size: 80%;
    font-weight: $weightMedium;
    background: linear-gradient(to bottom, rgba($bgColorSecondary, 0) 0%, rgba($bgColorSecondary, 0.85) 100%);
    opacity: 0;
  }

  &__DesktopOnly {
    background: rgba($bgColorSecondary, 0.80);
    border-radius: $radius;
  }

  @include supportsHover {
    .GameTile__Description {
      transition: all 200ms ease;
      // text-indent: -$marginMd1;
    }

    &:hover {
      .GameTile__Description {
        // text-indent: 0;
        opacity: 1;
      }
    }
  }
}

.AgGameTile__Label {
  color: #fff;

  &--classic {
    background-color: #E49130;
    box-shadow: darken(#E49130, 10%) 0 4px;
    &::after {
      background: darken(#E49130, 20%);
    }
  }

  &--popular {
    background-color: #6A30E4;
    box-shadow: darken(#6A30E4, 10%) 0 4px;
    &::after {
      background: darken(#6A30E4, 20%);
    }
  }

  &--updated {
    background-color: #306DE4;
    box-shadow: darken(#306DE4, 10%) 0 4px;
    &::after {
      background: darken(#306DE4, 20%);
    }
  }

  // &--featured {
  //   background-color: #E43D30;
  //   box-shadow: darken(#E43D30, 10%) 0 4px;
  //   &::after {
  //     background: darken(#E43D30, 20%);
  //   }
  // }

  &--trending {
    background-color: #E43D30;
    box-shadow: darken(#E43D30, 10%) 0 4px;
    &::after {
      background: darken(#E43D30, 20%);
    }
  }
}
