.ProfilePod__Avatar {
  border-color: $primaryColorInverted;
}

.ProfilePod__Stat {
  &--Label {
    color: $strongColor;
    font-weight: bold;
  }

  &--Value {
    color: darken($strongColor, 20);
  }
}