.GameTile {
  position: relative;
  cursor: pointer;
  display: block;
  width: $gameTileWidth;
  height: $gameTileHeight;
  border-radius: $gameTileBorderRadius;
  overflow: hidden;
  align-self: flex-start;
  user-select: none;

  &__Preview {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 0;
    word-spacing: 0;

    border-radius: $gameTileBorderRadius;
    overflow: hidden;
  }

  &__Description {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    pointer-events: none;
  }

  &__Status {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
  }

  &--featured {
    width: calc(#{$gameTileWidth * 2} + #{$gameTileGap});
    height: calc(#{$gameTileHeight * 2} + #{$gameTileGap});
  }

  &--fullWidth {
    width: 100% !important;
    height: auto;
  }

  &--stretch {
    height: 100%;
  }

  &--floating {
    position: absolute;
    z-index: 1;
  }

  &--disabled {
    cursor: not-allowed;
    
    .GameTileThumbnail,
    .GameTileVideoThumbnail {
      filter: grayscale(100%);
    }
  }
}

.GameTileStatus,
.GameTileDescription {
  padding: $gameTilePadding;
}

.GameTileVideoThumbnail {
  position: relative;
  width: 100%;
  height: 100%;

  video {
    background-color: transparent;
  }

  img {
    width: 100%;
    height: 100%;
  }

  &__Poster {
    pointer-events: none;
    transition: opacity 200ms ease;
    position: relative;
    z-index: 1;
  }

  &__Video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    z-index: 0;
  }
  
  &--play .GameTileVideoThumbnail__Poster {
    opacity: 0;
  }
}
