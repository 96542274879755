html {
  font-size: $rootFontSize;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;

  @include media('>=ultrahd') {
    font-size: $rootFontSizeLg;
  }

  @include isMobile {
    font-size: $rootFontSizeSm;
  }
}

body {
  display: flex;
  position: relative;
  min-height: 100svh;
  min-height: -webkit-fill-available;
  line-height: $lineHeight;
  overflow-x: hidden;
  flex-direction: column;

  &.--fullScreen {
    overflow: hidden !important;
    position: fixed;
  }
}

input,
select,
textarea {
  font-family: $fontFamily;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

code,
pre {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: auto;
  font-family: monospace;
}

hr {
  border: none;
  display: block;
  height: $hrHeight;
  margin: $hrMargin 0;
}

img {
  height: auto;
  max-width: 100%;
}
