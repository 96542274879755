@use 'sass:math';

// The contents of this file are globally available in every SASS file automatically, without importing
// This file is meant for global variables, mixins, functions, etc.
// DO NOT put any styles in this file. Global styles belong in app.scss.

/*
 * Global variables
 */

@import '@/common/styles/util/defaults';
@import '@/common/styles/util/defaultsDerived';
@import '@/common/styles/util';

$gameTileGap: 0.75rem;
$gameTileWidth: 11.25rem;
$gameTileHeight: 7.3125rem;

$bodyPadding: $marginMd0;
$bodySmallScreenPadding: $marginSm3;
$bodyMaxWidthUltrahd: 147.25rem;

$playlistTileHeight: 9.25rem;

$gameVideoBorderRadius: $radiusMd !default;

/*
 * Mixins
 */

$minGameTilesInGrid: 3;
$maxGameTilesInGrid: 12;
// 48.0625rem == 769px == desktop breakpoint
$gameGridWidth: 48.0625rem;
 
@mixin gameTileGridBreakpoints ($excludeTilesHD: 0, $excludeTilesUHD: 1) {
  @include media('>phone') {
    $tiles: max((2 - $excludeTilesHD), 2);
    $gap: ($tiles - 1) * $gameTileGap;
    $width: $tiles * $gameTileWidth;
    width: $width + $gap;
  }

  @include media('>=phoneLarge') {
    $tiles: max((3 - $excludeTilesHD), 3);
    $gap: ($tiles - 1) * $gameTileGap;
    $width: $tiles * $gameTileWidth;
    width: $width + $gap;
  }

  @for $i from $minGameTilesInGrid through $maxGameTilesInGrid {
    @include gameTileBreakpoint($i) {
      $tiles: max(($i - $excludeTilesHD), $minGameTilesInGrid);
      $gap: ($tiles - 1) * $gameTileGap;
      $width: $tiles * $gameTileWidth;
      width: $width + $gap;
    }
  }
}

@mixin gameTileBreakpoint ($numTiles) {
  $totalGameTileWidth: ($numTiles - $minGameTilesInGrid) * $gameTileWidth;
  $totalGameGapWidth: ($numTiles - $minGameTilesInGrid - 1) * $gameTileGap;

  @if $totalGameGapWidth < 0rem {
    $totalGameGapWidth: 0rem;
  }

  @media (min-width: #{$gameGridWidth + $totalGameTileWidth + $totalGameGapWidth}) {
    @content;
  }
}

/*
 * Functions
 */

// ...
