.BlockPod {
  &--box {
    color: $boxColor;
    background-color: $boxBgColor;
    box-shadow: $boxShadow;
  }

  &--transparentBg {
    background-color: rgba($theme, 0.8);
    backdrop-filter: blur(2px);
    box-shadow: $boxShadow;
  }
}
