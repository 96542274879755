.EditorialBlock__Image {
  background: $theme;
  border: solid 2px $primaryColor;
}

.EditorialBlock__Content {
  background: $bgColorSecondary;

  @if ($isDark == false) {
    color: $buttonColor;
  }
}

.EditorialBlock__Description {
  &::after {
    background: linear-gradient(rgba($bgColorSecondary, 0), rgba($bgColorSecondary, 0.65), $bgColorSecondary);
  }
}

.EditorialBlock__Socials {
  &::after {
    background: $buttonBorderColor;
  }

  @if ($isDark == false) {
    .RouterLink {
      color: $buttonColor;

      @include supportsHover {
        &:hover {
          color: lighten($primaryColor, 50);
        }
      }
    }
  }
}
