.AppFooter {
  border-top: solid 1px rgba($borderColor, 0.2);
  margin-top: $spacing;
  background-color: $theme;

  .RouterLink {
    color: $buttonColor;
  }

  p {
    opacity: 0.65;
  }
}

.AppFooter__Socials {
  .Icon {
    color: $primaryColor;
  }
}
