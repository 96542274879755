.AgSearchTabs {
  .Button {
    &:last-child {
      border-right: 2px solid #666666;
    }
    &:last-child:hover {
      border-right: 2px solid $primaryColor;
    }
    &--active {
      background-color: $primaryColor;
    }
    &--active:last-child {
      border-right: 2px solid $primaryColor;
    }
  }
}
