.Carousel {
  position: relative;

  &__Inner {
    position: relative;
    z-index: 0;
    display: grid;
    grid-gap: $gameTileGap;
    grid-auto-flow: column;
    
    @include isMobile {
      grid-template-rows: $gameTileMobileHeight $gameTileMobileHeight; 
      grid-template-columns: repeat(auto-fill, $gameTileMobileWidth);
    }

    @include isDesktop {
      grid-template-rows: $gameTileHeight;
      grid-template-columns: $gameTileWidth;
    }
  }

  &--featured {
    .Carousel__Inner {
      @include isDesktop {
        grid-template-rows: $gameTileHeight $gameTileHeight; 
        grid-template-columns: repeat(auto-fill, $gameTileWidth);

        .GameTile--featured {
          grid-row-end: span 2;
          grid-column-end: span 2;
        }
      }
    }
  }

  &--expandToEdge {
    margin: 0 (-$bodySmallScreenPadding);

    @include isDesktop {
      margin: 0 (-$bodyPadding);
    }

    .Carousel__Inner {
      padding: 0 $gameTileGap;
    }
  }

  &__Button {
    display: flex;
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    padding: 0 0.5rem;
    width: $gameTileWidth * 0.35;
    box-sizing: content-box;

    &::after {
      @include isTabletOrGreater {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
      }
    }

    .Button {
      padding: 1.65em;
      z-index: 2;

      .Icon {
        width: 1em;
        height: 1em;
      }
    }

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }
  }

  .GameTile {
    &:not(.GameTile--finished) {
      opacity: 0;
    }
  }
}
