.Hamburger {
  font-size: 125%;
  width: 1.3em;
  height: 1em;
  position: relative;
  cursor: pointer;

  &::before,
  &::after,
  span {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    border-top: solid 2px;
    top: calc(50% - 1px);
  }

  &::before {
    top: 0;
  }

  &::after {
    top: auto;
    bottom: 0;
  }

  // Modifiers
  &--rounded {
    &::before,
    &::after,
    span {
      border-radius: 1em;
    }
  }
}
