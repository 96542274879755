.Content {
  line-height: 1.4;

  > * {
    &:not(:last-child) {
      margin-bottom: $spacing * 0.5;
    }
  }

  ul,
  ol {
    padding-left: 1.25em;

    > li {
      &:not(:last-child) {
        margin-bottom: $spacing * 0.25;
      }
    }
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  &--large {
    > *:not(.Title):not(h1):not(h2):not(h3):not(h4):not(h5):not(h6) {
      font-size: 120%;
    }
  }
}
