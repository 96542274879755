@import '@/common/styles/theme';

// &.Page {
//   color: $fontColor;
//   background-color: $theme;
// }

hr {
  background-color: $hrColor;
}

.Button {
  transition: border-color 300ms ease;
  border-width: 1px;
  overflow: hidden;

  &[disabled],
  &--disabled {
    pointer-events: none;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    pointer-events: none;
    background: $primaryColor;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    z-index: 0;
    transform: scaleY(0);
    opacity: 0;
    transition-property: transform, opacity;
    transition-duration: 300ms;
    transition-timing-function: ease;
    transform-origin: 50% 100%;
  }

  > span {
    position: relative;
  }

  &:not(.Button--primary) {
    > span {
      > .Icon:first-of-type {
        color: $primaryColor;
        transition-property: color;
        transition-duration: 300ms;
        transition-timing-function: ease;
      }
    }
  }

  img {
    transition: filter 300ms ease;
  }

  @include isDesktop {
    &:hover {
      transition-duration: 0s;
      border-color: $primaryColor;

      > span {
        > .Icon:first-of-type {
          transition-duration: 0s;
          color: $primaryColorInverted;
        }
      }

      img {
        transition-duration: 0s;
        filter: brightness(10);
      }

      &::before {
        transition-duration: 0s;
        transform: scaleY(1);
        opacity: 1;
      }
    }
  }

  &--primary {
    color: $primaryColorInverted;

    &::before {
      background: lighten($primaryColor, 15);
    }

    @include isDesktop {
      &:hover {
        border-color: lighten($primaryColor, 15);
      }
    }
  }

  &--tertiary {
    border-color: $buttonBorderColor;

    @include isDesktop {
      &:hover {
        border-color: $primaryColor;
      }
    }
  }
}

.RouterLink {
  color: $primaryColor;
  font-weight: $weightMedium;
  
  @include isDesktop {
    &:hover {
      color: lighten($primaryColor, 15);
    }
  }
}

.ModalCard {
  border: solid 1px $bgColorSecondary;
  border-radius: $modalCardRadius;
}

.ModalCard__Head {
  font-weight: $weightMedium;
  text-transform: uppercase;
  padding-bottom: 0;
}

.Notification {
  color: $notificationColor;
  background-color: $notificationBgColor;
  border: solid 1px $lightColor;
  box-shadow: 0 3px 7px rgba(#000, 0.35);

  // Colors
  &--success {
    border-color: $successColor;
  }
  &--warning {
    border-color: $warningColor;
  }
  &--error {
    border-color: $errorColor;
  }
}

// Components
@import '@/components/AgAccordion/index.theme.scss';
@import '@/components/AgGameTile/index.theme.scss';
@import '@/components/AgFreeGameTile/index.theme.scss';
@import '@/components/AgSearchTabs/index.theme.scss';
@import '@/components/AppFooter/index.theme.scss';  
@import '@/components/AppHeader/index.theme.scss';  
@import '@/components/AvatarPicker/index.theme.scss';  
@import '@/components/BlockContainer/index.theme.scss';  
@import '@/components/BlockPod/index.theme.scss';  
@import '@/components/BlogPost/index.theme.scss';  
@import '@/components/CategoryCarousel/index.theme.scss';  
@import '@/components/CategoryTag/index.theme.scss';  
@import '@/components/CheckoutDetails/index.theme.scss';  
@import '@/components/DeveloperPod/index.theme.scss';  
@import '@/components/EditorialBlock/index.theme.scss';  
@import '@/components/FeaturedGameCarousel/index.theme.scss';  
@import '@/components/FluidCarousel/index.theme.scss';  
@import '@/components/gamePage/GPDescription/index.theme.scss';  
@import '@/components/gamePage/GPRatingUi/index.theme.scss';  
@import '@/components/GamePassOverlay/index.theme.scss';  
@import '@/components/GameReview/index.theme.scss';
@import '@/components/GameTileBlock/index.theme.scss';  
@import '@/components/InsetContainer/index.theme.scss';  
@import '@/components/PlaylistTile/index.theme.scss';
@import '@/components/ProductFeature/index.theme.scss';  
@import '@/components/ProductPlanBox/index.theme.scss';  
@import '@/components/RecentGamesBlock/index.theme.scss';  
@import '@/components/SearchMenu/index.theme.scss';
@import '@/components/StickyFooter/index.theme.scss';
@import '@/components/TaxonomyTile/index.theme.scss';
@import '@/components/RecentGamesBlock/index.theme.scss';
@import '@/components/UserAvatar/index.theme.scss';

// Layouts
@import '@/layouts/AboutPageLayout/index.theme.scss';

// Pages
@import '@/pages/[genre]/[slug]/index.theme.scss';
@import '@/pages/all-games/index.theme.scss';
@import '@/pages/blog/index.theme.scss';
@import '@/pages/blog/[slug]/index.theme.scss';
@import '@/pages/search/index.theme.scss';
@import '@/pages/all-categories/index.theme.scss';
@import '@/pages/all-games/index.theme.scss';
@import '@/pages/multiplayer-games/index.theme.scss';
@import '@/pages/users/[user]/index.theme.scss';
@import '@/pages/free-games/index.theme.scss';
@import '@/pages/pandemic-2-game-guide/index.theme.scss';