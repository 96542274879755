body[data-theme=classic] {
  $isDark: false;

  @import '@/common/styles/util/themePalette';
  @import '@/common/styles/util/themeDefaults';

  // Palette
  $agWhite: #FFF;
  $agOffWhite: #d5d9de;
  $agBlack: #050505;
  $agLightBlue: #869BBF;
  $agBlue: #2E4B82;
  $agGray: #2E4B82;

  // Theme variables
  $theme: $agOffWhite;
  $themeInverted: $agWhite;
  $bgColor: $agLightBlue;
  $bgColorSecondary: $agBlue;

  $fontColor: $agBlack;
  $lightColor: lighten($agBlack, 20);
  $strongColor: $fontColor;
  $titleColor: $agBlue;
  $subtitleColor: $fontColor;

  $primaryColor: $agBlue;
  $primaryColorInverted: $agWhite;
  $secondaryColor: $agWhite;
  $secondaryColorInverted: $agLightBlue;
  $tertiaryColor: $agGray;
  $tertiaryColorInverted: $agWhite;

  $errorColor: #f00;

  $inputColor: $agWhite;
  $inputBgColor: $bgColorSecondary;
  $inputShadow: none;
  $inputBorderColor: $bgColorSecondary;
  $inputIconColor: $agLightBlue;
  $inputIconActiveColor: $agWhite;

  $buttonBgColor: $agLightBlue;
  $buttonColor: $agWhite;
  $buttonBorderColor: $agWhite;
  $buttonHoverColor: $agWhite;
  $buttonActiveColor: $agWhite;
  $buttonFocusColor: $agWhite;

  $boxBgColor: $bgColor;
  $boxShadow: none;

  $loaderSecondaryColor: $agBlue;

  $gameObjectErrorColor: $bgColor;
  $gameObjectBgColor: $bgColor;
  $gameObjectPreviewColor: rgba($bgColor, 0.85);
  $gameObjectPreviewBlendMode: none;

  $modalBgColor: rgba($bgColorSecondary, 0.8);
  $modalBgFilter: blur(2px);
  $modalCardHeadBgColor: $bgColor;
  $modalCardBorderColor: $bgColor;
  $modalCardBodyBgColor: $bgColor;

  $dropdownItemColor: $agWhite;
  $dropdownItemActiveBgColor: $agLightBlue;
  $dropdownContentBgColor: $bgColorSecondary;
  $dropdownContentShadow: none;
  $dropdownDividerBgColor: $lightColor;
  
  // Custom component variables
  $gamePassOverlaySvgFill: $bgColorSecondary;
  $categoryTagBg: $bgColorSecondary;
  $featuredCarouselSlideImageShadow: 0 10px 30px $agBlue;
  $productPlanBoxHeaderBg: $bgColorSecondary !default;
  $hrColor: $bgColorSecondary;

  color: $fontColor;
  background-color: $theme;

  $positiveReview: #30e43d;
  $negativeReview: #e43d30;

  ::selection {
    color: $agWhite;
    background: rgba($primaryColor, 0.35);
  }

  .RouterLink {
    color: $primaryColor;
    text-decoration: underline;
  }

  .MenuList {
    background: $bgColor;
    border: solid 1px $inputBorderColor;
    border-radius: $radius;
  }

  .Autocomplete .Input,
  .Input {
    color: $fontColor !important;
    background-color: $agWhite !important;
    border-color: $agWhite !important;

    &::placeholder {
      color: $agLightBlue !important;
    }

    &:focus {
      ~ .Icon {
        color: $agBlue !important;
      }
    }
  }

  .Delete {
    background-color: $agBlue !important;
    &:hover {
      background-color: rgba($agBlue, 0.85) !important;
    }
    &:active {
      background-color: rgba($agBlue, 1) !important;
    }
  
    &::before,
    &::after {
      background-color: $agWhite;
    }
  }

  .Title,
  .BlockHeader,
  .GamePage .Box h4,
  .GamePage .GamePlayer h1,
  .GamePage__Game__RatingTitle h1 {
    color: $titleColor;
    font-family: 'Comic Sans', 'Comic Sans Ms', 'Chalkboard', 'ChalkboardSE-Regular', sans-serif;
    letter-spacing: -1px;
    font-weight: bold;
  }

  .Checkbox,
  .Radio {
    input + span {
      background-color: $agLightBlue !important;
    }
    input[checked] + span {
      background-color: $agBlue !important;
    }
  }

  @keyframes pageLoaderGlowClassic {
    0% {
      background-color: darken($primaryColor, 10);
    }
  
    45% {
      background-color: lighten($primaryColor, 10);
    }
  
    55% {
      background-color: lighten($primaryColor, 10);
    }
  
    100% {
      background-color: darken($primaryColor, 10);
    }
  }
  .PageLoader {
    animation-name: pageLoaderGlowClassic;
  }

  .AppFooter__Logo,
  .MobileHeader__Logo {
    img {
      width: 175px;
      height: 32px;
    }
  }

  .Button {
    &:not(.Button--primary) {
      > span {
        > .Icon:first-of-type {
          color: $primaryColorInverted !important;
        }
      }
    }
  }

  .DesktopHeader__DropdownInner {
    &:last-child {
      color: $primaryColorInverted !important;
    }
  }

  .TaxonomyTile {
    color: $primaryColorInverted;
  }

  .AppFooter .RouterLink {
    color: $primaryColor !important;
  }

  .FluidCarousel__Wrapper {
    &::before {
      background: linear-gradient(to left, rgba($agOffWhite, 0), rgba($agOffWhite, 0.7), rgba($agOffWhite, 1)) !important;
    }
    &::after {
      background: linear-gradient(to right, rgba($agOffWhite, 0), rgba($agOffWhite, 0.7), rgba($agOffWhite, 1)) !important;
    }
  }

  @import '@/common/styles/util/themeDerived';
  @import '@/styles/util/themeVariables';
  @import '@/styles/util/theme';
}
