.Ad {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;

  > * {
    margin: auto;
    font-size: 100%;
    word-spacing: normal;
  }
}

.AdContainer {
  margin: auto;
  pointer-events: all;
  position: relative;
  z-index: 1;
  font-size: 0;
  word-spacing: 0;
  width: 100%;
  height: 100%;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;

  // Sizes
  &--banner {
    width: 468px;
    height: 60px;
  }
  &--mobileBanner {
    width: 320px;
    height: 50px;
  }
  &--halfPage {
    width: 300px;
    height: 600px;
  }
  &--largeLeaderboard {
    width: 970px;
    height: 90px;
  }
  &--largeRect {
    width: 336px;
    height: 280px;
  }
  &--leaderboard {
    width: 728px;
    height: 90px;
  }
  &--mediumRect {
    width: 300px;
    height: 250px;
  }
  &--mobileLeaderboard {
    width: 320px;
    height: 50px;
  }
  &--skyscraper {
    width: 120px;
    height: 600px;
  }
  &--smallSquare {
    width: 200px;
    height: 200px;
  }
  &--square {
    width: 250px;
    height: 250px;
  }
  &--wideSkyscraper {
    width: 160px;
    height: 600px;
  }

  &::after {
    content: '';
    font-size: 10px;
    letter-spacing: 1px;
    position: absolute;
    pointer-events: none;
    z-index: -1;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    left: 0;
    text-align: center;
    opacity: 0.5;
  }
}
