.ProductPlanBox {
  background-color: $productPlanBoxBg;

  &--featured {
    box-shadow: 0 0 0 2px $productPlanBoxColor, 0 5px 40px 2px rgba($productPlanBoxColor, 0.65);
  }
}

.ProductPlanBox__Header {
  background-color: $productPlanBoxHeaderBg;

  @if ($isDark: false) {
    color: $buttonColor;
  }
}

.ProductPlanBox__Sticker {
  background-color: $productPlanBoxColor;
  color: $productPlanBoxColorInverted;
}