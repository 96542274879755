@use 'sass:math';
$breakpoints: (
    phoneSmall: 375px,
  phone: 450px,
  phoneLarge: 575px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.GameTileGrid {
  position: relative;
  grid-gap: $gameTileGap;

  @include media('<=phone') {
    &--heightOneRowMobile {
      max-height: $gameTileHeight;
      overflow: hidden;
    }

    &--heightOneRow,
    &--heightTwoRows {
      max-height: ($gameTileHeight * 2) + ($gameTileGap * 1);
      overflow: hidden;
    }

    &--heightPlaylistOneRow,
    &--heightPlaylistTwoRows {
      max-height: ($playlistTileHeight * 2) + ($gameTileGap * 1);
      overflow: hidden;
    }
  }

  @include media('>phone') {
    &--heightOneRow,
    &--heightOneRowMobile {
      max-height: $gameTileHeight;
      overflow: hidden;
    }

    &--heightTwoRows {
      max-height: ($gameTileHeight * 2) + $gameTileGap;
      overflow: hidden;
    }

    &--heightPlaylistOneRow {
      max-height: $playlistTileHeight;
      overflow: hidden;
    }

    &--heightPlaylistTwoRows {
      max-height: ($playlistTileHeight * 2) + $gameTileGap;
      overflow: hidden;
    }
  }

  // &:not(.GameTileGrid__Overflow) {
  //   @include media('<=phone') {
  //     .GameTile:not(.PlaylistTile__Content) {
  //       width: calc(50% - #{$gameTileGap * 0.5});
  //     }
  //   }
  // }
}

.GameTileGrid__Overflow {
  overflow: visible;
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: $gameTileHeight $gameTileHeight;

  // @include media('<=phone') {
  //   $width: calc((100svw * 0.5) - 0.75rem - #{$gameTileGap * 0.5});
  //   grid-template-columns: repeat(auto-fill, $width);

  //   .GameTile {
  //     width: $width;
  //   }
  // }

  @include media('>phone') {
    grid-template-columns: repeat(auto-fill, $gameTileWidth);
  }
}

.Box .GameTileGrid__Overflow {
  @include media('<=phone') {
    $width: calc((100svw * 0.5) - 0.75rem - #{$gameTileGap * 0.5} - #{$boxPadding});
    grid-template-columns: repeat(auto-fill, $width);

    .GameTile {
      width: $width;
    }
  }
}
