.Listed__Category {
  &__Inner {
    background: $bgColor;
    box-shadow: 0 0 0 2px rgba($primaryColor, 0), 0 4px 11px rgba(#000, 0.4);
    transition: box-shadow 400ms ease;

    &:hover {
      @include isDesktop {
        transition-duration: 0s;
        box-shadow: 0 0 0 2px $primaryColor, 0 4px 11px rgba(#000, 0.4);
      }
    }
  }
}
