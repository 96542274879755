// Colors
$black: hsl(0, 0%, 4%) !default;
$greyDark:hsl(0, 0%, 29%) !default;
$grey: hsl(0, 0%, 48%) !default;
$greyLight: hsl(0, 0%, 71%) !default;
$greyLighter: hsl(0, 0%, 86%) !default;
$greyLightest: hsl(0, 0%, 93%) !default;
$white: hsl(0, 100%, 99%) !default;
$orange: hsl(14,  100%, 53%) !default;
$yellow: hsl(44,  100%, 77%) !default;
$green: hsl(153, 53%,  53%) !default;
$turquoise: hsl(171, 100%, 41%) !default;
$cyan: hsl(207, 61%,  53%) !default;
$blue: hsl(229, 53%,  53%) !default;
$purple: hsl(271, 100%, 71%) !default;
$red: hsl(348, 86%, 61%) !default;
