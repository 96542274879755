.AppHeader {
  background: $theme;
  border-bottom: solid 1px $buttonBorderColor;
}

.DesktopHeader {
  .UserAvatar {
    cursor: pointer;
    
    @include isDesktop {
      transition: border-color 200ms ease;

      &:hover {
        transition-duration: 0s;
        border-color: lighten($primaryColor, 15);
      }
    }
  }
}


.DesktopHeader__DropdownOverlay {
  backdrop-filter: $modalBgFilter;
  background: $modalBgColor;
}

.DesktopHeader__GamepassUpgradeButton {
  border-color: $primaryColor;
}

.DesktopHeader__Dropdown {
  border: solid 1px $buttonBorderColor;
  border-top: none;
  background: $theme;
}

.DesktopHeader__DropdownInner {
  &:last-child {
    background-color: darken($bgColor, 2.75);
    color: $lightColor;
  }
}

.MobileHeader {
  .--userPanel {
    pointer-events: none;
    transform-origin: 100% 100%;
    opacity: 0;
    transform: scale(0.85);
    transition-property: opacity, transform;
    transition-duration: 200ms;
    transition-timing-function: ease;
    background-color: $modalCardBodyBgColor;
    border-radius: $radius;
  }

  &--userPanelOpen .--userPanel {
    pointer-events: all !important;
    opacity: 1 !important;
    transform: scale(1) !important;
  }

  .AgSearch {
    box-shadow: 0 3px 7px rgba(#000, 0.4);
    border-radius: 100px;

    .Input {
      background-color: $modalCardBodyBgColor;
    }
  }
}

.MobileHeader__Menu,
.MobileHeader__Menu__ExtraButtonItem {
  > .Button {
    box-shadow: 0 3px 7px rgba(darken($primaryColor, 30), 0.65);
  }
}

.MobileHeader__Menu__Bg {
  background: $modalBgColor;
}

.MobileHeader__MoreGamesPanel {
  background-color: $modalCardBodyBgColor;
  box-shadow: 0 4px 13px rgba($theme, 0.65);
}

.MobileHeader__Menu__ExtraButtonItem {
  opacity: 0;

  .MobileHeader__Menu__Panel {
    pointer-events: none;
    transform-origin: 100% 100%;
    opacity: 0;
    transform: scale(0.85);
    transition-property: opacity, transform;
    transition-duration: 200ms;
    transition-timing-function: ease;
    background-color: $modalCardBodyBgColor;
  }
}

@keyframes dropTargetPulseDefault {
  0% {
    box-shadow: 0 0 0 0px rgba(#e43d30, 0);
  }
  50% {
    box-shadow: 0 0 0 30px rgba(#e43d30, 0.5);
  }
  100% {
    box-shadow: 0 0 0 60px rgba(#e43d30, 0);
  }
}

@keyframes dropTargetPulseClassic {
  0% {
    box-shadow: 0 0 0 0px rgba(#2E4B82, 0);
  }
  50% {
    box-shadow: 0 0 0 30px rgba(#2E4B82, 0.5);
  }
  100% {
    box-shadow: 0 0 0 60px rgba(#2E4B82, 0);
  }
}

.MobileHeader__DropTargets {
  > div {
    background-color: rgba($primaryColor, 0.85);
    animation: dropTargetPulseClassic 1s infinite;

    @if $isDark {
      animation: dropTargetPulseDefault 1s infinite;
    }
  }
}

.GamePass__Banner {
  border-top-color: $primaryColor;
  border-bottom-color: $primaryColor;
  background-color: $theme;
}