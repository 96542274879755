.SearchPage {
  .SearchPage__Container {
    padding: $marginMd0;
    border-radius: $radius;

    @include media('>=desktop') {
      background-color: $bgColor;
    }
    .Game__Search__Table__Row {
      &--right {
        border-left: 2px solid $primaryColorInverted;
        padding-left: $marginSm3;
      }
      .Game__Search__Table__Stats {
        margin-bottom: 10px;
        &__Rating {
          background-color: $primaryColor;
          width: 45px;
          height: 45px;
          font-weight: bold;
          border-radius: $marginSm3;
          margin-right: $marginSm3;
        }
      }
    }
  }
} 