.GamePage__Game__Toolbar {
  background-color: $gamePageToolbarBg;
}

.GamePage__ThumbnailPreview {
  transition: box-shadow 400ms ease;
  border: solid 1px rgba($bgColor, 0.6);
  box-shadow: 0 0 0 4px $primaryColor, 0 6px 15px rgba(#000, 0.4);

  &:hover {
    transition-duration: 0s;
    box-shadow: 0 0 0 4px lighten($primaryColor, 10), 0 6px 15px rgba(#000, 0.4);
  }
}

#gamePreroll {
  background-color: $bgColor;
}

.GamePage__Unlock {
  small {
    opacity: 0.6;
  }

  strong {
    color: $primaryColor;
  }
}

.GPSidebarAd {
  &__Inner {
    background-color: $bgColor;
  }
}

.GamePage__Game__Main {
  @if ($isDark == false) {
    background-color: $bgColor;
  } @else {
    background-color: #000;
  }
}