.GamePlayer {
  position: relative;
  z-index: 1;
  user-select: none;
  min-width: 300px;
  min-height: 150px;
  overflow: hidden;

  &--fluid {
    width: 100%;
    .GamePlayer__Game {
      width: 100%;
      height: 100%;
      z-index: 2;

      object,
      iframe,
      ruffle-object {
        position: absolute;
      }

      ruffle-embed {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__Overlay,
  &__PreviewOverlay,
  &__PreviewOverlay::after,
  &__Incompatible {
    position: absolute;
    pointer-events: none;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
  }

  &__Overlay,
  &__PreviewOverlay {
    z-index: 2;
    > * {
      pointer-events: auto;
    }
  }

  &__PreviewOverlay {
    &--remove {
      @include media ('<=tablet') {
        display: none;
      }
    }
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    &::after {
      content: '';
      display: block;
    }
  }

  &__Game {
    z-index: 1;
    position: relative;
    pointer-events: none;

    object,
    iframe {
      position: relative;
      pointer-events: auto;
      z-index: 1;
    }
  }

  &__Anchor {
    position: absolute;
    pointer-events: none;
    z-index: 4;

    > * {
      pointer-events: auto;
    }

    &--top {
      top: $spacing;
    }
    &--right {
      right: $spacing;
    }
    &--bottom {
      bottom: $spacing;
    }
    &--left {
      left: $spacing;
    }
  }

  &__Incompatible {
    z-index: 3;
    pointer-events: all;
    padding: $spacing;
    text-align: center;

    @include supportsHover {
      &--mobile {
        display: none;
      }
      &--portrait {
        display: none;
      }
      &--landscape {
        display: none;
      }
    }
  }

  &--fullScreen {
    position: fixed;
    z-index: $fixedZ;

    .GamePlayer__Game {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100% !important;
      height: 100% !important;

      object,
      iframe {
        position: absolute;
      }
    }
  }

  &__CloseButton {
    font-weight: lighter;
    background: rgba(255,255,255,0.2);
    width: 1.25rem;
    height: 1.25rem;
    display: none;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border-radius: 1rem;
    backdrop-filter: blur(4px);
    color: #fff;
    font-family: sans-serif;
    user-select: none;
    cursor: pointer;

    @include media ('>=widescreen') {
      display: flex;
    }
  }
}
