.MenuList {
  a {
    color: $menuItemColor;
    &:hover {
      background-color: $menuItemHoverBgColor;
      color: $menuItemHoverColor;
    }    

    // Modifiers
    &.--active,
    &.RouterLink--active {
      color: $menuItemActiveColor;
      background-color: $menuItemActiveBgColor;
    }
  }

  li {
    ul {
      border-left: $menuListBorderLeft;
    }
  }
}

.MenuLabel {
  color: $menuLabelColor;
}
