.Delete {
  position: relative;
  border-radius: $radiusRounded;
  cursor: pointer;
  user-select: none;
  appearance: none;
  pointer-events: auto;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 0;
  outline: 0;
  vertical-align: top;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;

  &::before,
  &::after {
    position: absolute;
    display: block;
    content: '';
    width: 2px;
    height: 1rem;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }

  // Sizes
  &--large {
    width: 3rem;
    height: 3rem;
    &::before,
    &::after {
      width: 4px;
      height: 1.98rem;
    }
  }
}
