$labelColor: $strongColor !default;

.FieldLabel {
  color: $labelColor;
}

.FieldHelp {
  // Colors
  @include resultStates;
}
