.Modal {
  @include overlay;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  z-index: $fixedZ + 2;
  pointer-events: none;

  > * {
    pointer-events: auto;
  }
}

.ModalCard {
  display: flex;
  flex-direction: column;
  margin: 0 $modalContentMargin;
  max-height: calc(100svh - #{$modalContentMargin * 2});
  overflow: hidden;
  position: relative;
  width: 100%;

  // Responsiveness
  &:not(.ModalCard--autoWidth) {
    @include isTabletOrGreater {
      margin: 0 auto;
      max-width: $modalContentWidth;
    }
  }
  &--autoWidth {
    @include isTabletOrGreater {
      margin: 0 auto;
      width: auto;
      min-width: map-get($breakpoints, 'phone');
    }
  }
  &--halfHeight {
    @include isDesktop {
      min-height: 400px;
      max-height: 50vh;
    }
  }

  &__Head,
  &__Foot {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    justify-content: flex-start;
    padding: $modalCardPadding;
    position: relative;
  }

  &__Head {
    border-bottom: $modalCardBorder;
    border-top-left-radius: $modalCardRadius;
    border-top-right-radius: $modalCardRadius;
  }

  &__Title {
    flex-grow: 1;
    flex-shrink: 0;
    font-size: $modalCardTitleFontSize;
    line-height: $modalCardTitleLineHeight;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  &__Foot {
    border-bottom-left-radius: $modalCardRadius;
    border-bottom-right-radius: $modalCardRadius;
    border-top: $modalCardBorder;
    .Button {
      &:not(:last-child) {
        margin-right: 0.5em;
      }
    }
  }

  &__Body {
    @include overflowTouch;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: auto;
    padding: $modalCardPadding;

    &:first-child {
      border-radius: $modalCardRadius $modalCardRadius 0 0;
    }

    &:last-child {
      border-radius: 0 0 $modalCardRadius $modalCardRadius;
    }

    &:only-child {
      border-radius: $modalCardRadius;
    }
  }
}

.Modals {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: $fixedZ + 2;

  &__Bg {
    @include overlay;
    position: absolute;
    z-index: 1;
    pointer-events: auto;
  }

  &__Content {
    margin: 0 $modalContentMargin;
    max-height: calc(100svh - #{$modalContentMargin * 2});
    overflow: auto;
    position: relative;
    width: 100%;
    max-width: calc(100svw - #{$modalContentMargin * 2});
    z-index: 2;

    // Responsiveness
    @include isDesktop {
      margin: 0 auto;
      max-width: $modalContentWidth;
    }
  }

  > .Delete {
    position: absolute;
    top: $modalCloseSpacing * 0.5;
    right: $modalCloseSpacing * 0.5;
    z-index: 3;

    @include isDesktop {
      top: $modalCloseSpacing;
      right: $modalCloseSpacing;
    }
  }
}
