@use 'sass:math';
$breakpoints: (
    phoneSmall: 375px,
  phone: 450px,
  phoneLarge: 575px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.AgGameTile {
  background-image: url('/images/emptyThumbnail.svg');
  background-size: cover;
  flex-shrink: 0;
  border-radius: $radius;
  // overflow: hidden;
  position: relative;

  &--floating {
    position: absolute;
    z-index: 1;
  }

  &--small {
    @include supportsHover {
      width: $gameTileWidth * 0.5;
      height: $gameTileHeight * 0.5;

      .GameTile__Description {
        display: none;
      }
    }
  }

  @include supportsTouch {
    .GameTile__Description {
      display: none !important;
    }
  }

  .GameTile__Status {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    pointer-events: none;
  }

  &:empty {
    pointer-events: none;
  }
}

.AgGameTile__DesktopOnly {
  display: flex;
  height: 100%;
  padding: 1rem 0.8rem;
  text-transform: uppercase;
  font-size: 105%;
  font-weight: 900;  
  text-shadow: 0 1px 1px rgba(#000, 0.35);
  letter-spacing: 0.25px;
  line-height: 1;
  white-space: nowrap;
  
  .Icon {
    /* This icon is not 1:1, so having the height at 1rem is fine */
    width: 1.25rem;
    height: 1rem;
    margin-right: 0.4rem;
  }

  /* 
   *  This media declaration checks whether there is a pointing device present.
   *  If not, we can assume this is a mobile device.
   */
  @media (hover: hover) {
    @include isDesktop {
      display: none;
    }
  }
}

.AgGameTile__Label {
  padding: $marginSm1 $marginSm2;
  border-radius: 7px 7px 0 7px;
  position: absolute;
  top: -4px;
  right: -4px;
  font-size: 65%;
  text-transform: uppercase;
  font-weight: bold;

  &::after {
    content: '';
    display: block;
    position: absolute;
    right: 0px;
    top: 28px;
    width: 4px;
    height: 4px;
    border-radius: 0 0 4px 0;
  }
}
