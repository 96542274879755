.StickyFooter {
  @if ($isDark == false) {
    color: $buttonColor;
    background: $bgColor;
  } @else {
    background: $bgColorSecondary;
    color: $fontColor;
  }

  box-shadow: 0 -5px 15px rgba(#000, 0.35);
}
