.Listed__Game {
  &__Inner {
    background: $bgColor;
    transition-duration: 400ms;
    transition-timing-function: ease;
    transition-property: box-shadow;

    @include isDesktop {
      &:hover {
        transition-duration: 0s;
        box-shadow: 0 0 0 1px $primaryColor;
      }
    }
  }
}