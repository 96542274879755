@use 'sass:math';
$breakpoints: (
    phoneSmall: 375px,
  phone: 450px,
  phoneLarge: 575px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

// Global styles

$fontFamily: 'Lato', sans-serif;
$titleFamily: $fontFamily;
$inputFamily: $fontFamily;
$rootFontSizeLg: 16px;

@import '@/common/styles';

body {
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-gutter: stable;
  font-family: $fontFamily;
}

strong {
  font-weight: $weightMedium;
}

#__next {
  position: relative;
  width: 100%;
  overflow-x: hidden;
}

.Button {
  font-weight: $weightMedium;
}

button,
input,
textarea {
  font-family: $fontFamily;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

hr {
  margin: $marginMd2 0;
  border: none;
  height: 1px;
}

.ympb_usp {
  z-index: 1 !important;
  margin-top: 0px !important;
  padding-bottom: 0.5em !important;

  @include media('>=widescreen') {
    margin-top: -114px !important;
    padding-bottom: 114px !important;
  }
}