.Autocomplete {
  &__Menu {
    background-color: $theme;
    border: 1px solid $buttonBorderColor;
    box-shadow: $dropdownContentShadow;
  }

  @if $autocompleteInputShadow {
    .Input {
      box-shadow: $autocompleteInputShadow;
    }
  }
}

.AutocompleteItem {
  color: $dropdownItemColor;
}

a.AutocompleteItem {
  &:hover {
    background-color: $dropdownItemHoverBgColor;
    color: $dropdownItemHoverColor;
  }
  &--active {
    background-color: $dropdownItemActiveBgColor !important;
    color: $dropdownItemActiveColor !important;
  }
}

.AutocompleteDivider {
  background-color: $dropdownDividerBgColor;
}
