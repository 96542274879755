.CategoryTag {
  color: $buttonColor;
  border: solid 1px rgba($primaryColor, 0);
  background-color: $categoryTagBg;
  transition: border-color 200ms ease;

  @include isDesktop {
    &:hover {
      transition: border-color 0s ease;
      border-color: $primaryColor;
    }
  }
  &--active {
    border-color: $primaryColor;
  }
}
