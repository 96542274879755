.PageLoader {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  pointer-events: none;
  transform-origin: 0% 0%;
  transform: scaleX(0);
  opacity: 0;
}
