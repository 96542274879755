.Menu {
  font-size: $fontSize;
  user-select: none;
  width: 100%;
}

.MenuList {
  line-height: $menuListLineHeight;

  a {
    border-radius: $menuItemRadius;
    display: block;
    padding: $menuListLinkPadding;
    cursor: pointer;

    // Modifiers
    &.--active,
    &.RouterLink--active {
      cursor: default;
    }
  }

  li {
    ul {
      margin: $menuNestedListMargin;
      padding-left: $menuNestedListPaddingLeft;
    }
  }

  hr {
    margin: $spacing 0;
  }
}

.MenuLabel {
  font-size: $menuLabelFontSize;
  letter-spacing: $menuLabelLetterSpacing;
  text-transform: uppercase;
  &:not(:first-child) {
    margin-top: $menuLabelSpacing;
  }
  &:not(:last-child) {
    margin-bottom: $menuLabelSpacing;
  }
}
