.AgAccordion {
  &--active {
    border-color: $buttonBorderColor !important;

    img {
      filter: none !important;
    }
    
    &::before {
      opacity: 0 !important;
    }
    
  }
}
