.Loader {
  &::after {
    @include loader($loaderColor);
  }
  &--large {
    &::after {
      font-size: 500%;
    }
  }
}

.LoaderFill {
  background: $loaderFillBg;
  backdrop-filter: $loaderFillBgFilter;
}
