.Autocomplete {
  position: relative;
  vertical-align: top;
  
  // Modifiers
  &--active,
  &--hoverable:hover {
    .Autocomplete__Menu {
      display: block !important;
    }
  }
  &--right {
    .Autocomplete__Menu {
      left: auto;
      right: 0;
    }
  }
  &--up {
    .Autocomplete__Menu {
      bottom: 100%;
      padding-bottom: $dropdownContentOffset;
      padding-top: initial;
      top: auto;
    }
  }
  
  &__Menu {
    left: 0;
    width: 100%;
    position: absolute;
    top: 100%;
    z-index: $dropdownContentZ;
    min-width: 30rem;
    border-radius: 0px 0px 4px 4px;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  &__Content {
    padding: 15px;

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  .Icon {
    width: 1.25rem;
  }
}
