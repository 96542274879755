.BlogPost {
  cursor: pointer;

  @include isDesktop {
    &:hover {
      .BlogPost__Image {
        &::after {
          transition-duration: 0s;
          box-shadow: 0 0 0 2px $primaryColor inset;
        }
      }
    }
  }

  p:empty {
    &:after {
      background-color: $bgColorSecondary;
    }
  }
}
  
.BlogPost__Image {
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: box-shadow 200ms ease;
    box-shadow: 0 0 0 0 $primaryColor inset;
    border-radius: 0.75rem;
  }
}