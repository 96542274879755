.PlaylistTile {
  position: relative;

  @include isDesktop {
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: $radius;
      pointer-events: none;
      z-index: 1;
      box-shadow: 0 0 0 0 rgba($primaryColor, 0) inset;
      transition: box-shadow 1s ease;
    }

    &:hover {
      &::after {
        transition: box-shadow 0s ease;
        box-shadow: 0 0 0 2px rgba($primaryColor, 1) inset;
      }

      .PlaylistTile__Title {
        transition: color 0s ease;
        color: $primaryColor;
      }
    }
  }
}

.PlaylistTile__Title {
  transition: color 1s ease;
  background-color: $bgColor;
}