.GameTileBlock__Overlay {
  $overlayColor: $bgColor;

  background-image: linear-gradient(
    to left, 
    rgba($overlayColor, 0),
    rgba($overlayColor, 0.85),
    rgba($overlayColor, 0.85),
    rgba($overlayColor, 0)
  );
  border-color: $primaryColor;
  backdrop-filter: blur(2px);
}
