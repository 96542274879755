.FeaturedGameCarouselThumbnail {
  background-color: $bgColorSecondary;
}

@include media('>=phoneLarge') {
  .FeaturedGameCarouselSlide__Image {
    img {
      box-shadow: $featuredCarouselSlideImageShadow;
      background-color: $theme;
    }
  }
}
