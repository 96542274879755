@use 'sass:math';
$breakpoints: (
    phoneSmall: 375px,
  phone: 450px,
  phoneLarge: 575px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.AvatarPicker {
  position: relative;
}

.AvatarPicker__Avatars {
  position: relative;

  img {
    width: 4rem;
    height: 4rem;
    border-radius: $radius;

    @include isDesktop {
      cursor: pointer;
      transition: box-shadow 200ms ease;

      &:hover {
        transition-duration: 0s;
        box-shadow: 0 0 0 2px;
      }
    }
  }
}