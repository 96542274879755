body[data-theme=default] {
  $isDark: true;

  background-image: url('/images/bg.png');

  @import '@/common/styles/util/themePalette';
  @import '@/common/styles/util/themeDefaults';

  // Palette
  $agBlack: #171717;
  $agDarkGray: #292929;
  $agGray: #3c3c3c;
  $agRed: #e43d30;
  $agWhite: #fff;

  // Theme variables
  $theme: $agBlack;
  $themeInverted: $agWhite;
  $bgColor: $agDarkGray;
  $bgColorSecondary: $agGray;

  $fontColor: #fff;
  $lightColor: #7f7f7f;
  $strongColor: $fontColor;
  $titleColor: $fontColor;
  $subtitleColor: $fontColor;

  $primaryColor: $agRed;
  $primaryColorInverted: $agWhite;
  $secondaryColor: $agWhite;
  $secondaryColorInverted: $agBlack;
  $tertiaryColor: $agDarkGray;
  $tertiaryColorInverted: $agWhite;

  $errorColor: $agRed;
  $warningColor: #FA0;
  $successColor: #1C5;

  $inputBgColor: $agDarkGray;
  $inputShadow: none;
  $inputBorderColor: $bgColorSecondary;
  $inputIconColor: $lightColor;
  $inputIconActiveColor: $primaryColor;

  $buttonBorderColor: $agGray;
  $buttonHoverColor: $primaryColorInverted;
  $buttonActiveColor: $primaryColorInverted;
  $buttonFocusColor: $primaryColorInverted;

  $boxBgColor: $bgColor;
  $boxShadow: none;

  $gameObjectErrorColor: $bgColor;
  $gameObjectBgColor: $bgColor;
  $gameObjectPreviewColor: rgba($bgColor, 0.8);
  $gameObjectPreviewBlendMode: none;

  $modalBgColor: rgba(#000, 0.9);
  $modalBgFilter: blur(2px);
  $modalCardHeadBgColor: $theme;
  $modalCardBorderColor: $theme;
  $modalCardBodyBgColor: $theme;

  $notificationBgColor: lighten($bgColor, 5);

  $dropdownContentBgColor: $bgColorSecondary;
  $dropdownContentShadow: none;
  $dropdownDividerBgColor: $lightColor;
  
  // Custom component variables
  $gamePassOverlaySvgFill: $bgColorSecondary;
  $categoryTagBg: $bgColorSecondary;
  $featuredCarouselSlideImageShadow: 0 10px 30px $agBlack;
  $productPlanBoxHeaderBg: $bgColorSecondary !default;
  $hrColor: $bgColorSecondary;

  color: $fontColor;
  background-color: $theme;

  $positiveReview: #30e43d;
  $negativeReview: $agRed;

  ::selection {
    color: $agWhite;
    background: rgba($primaryColor, 0.35);
  }

  .MenuList {
    background: $bgColor;
    border: solid 1px $inputBorderColor;
    border-radius: $radius;
  }

  @keyframes pageLoaderGlowDefault {
    0% {
      background-color: darken($primaryColor, 10);
    }
  
    45% {
      background-color: lighten($primaryColor, 10);
    }
  
    55% {
      background-color: lighten($primaryColor, 10);
    }
  
    100% {
      background-color: darken($primaryColor, 10);
    }
  }
  .PageLoader {
    animation-name: pageLoaderGlowDefault;
  }

  .AppFooter__Logo,
  .MobileHeader__Logo {
    img {
      width: 274px;
      height: 24px;
    }
  }

  .Autocomplete,
  .Input {
    &::placeholder {
      color: $lightColor !important;
    }
  }

  @import '@/common/styles/util/themeDerived';
  @import '@/styles/util/themeVariables';
  @import '@/styles/util/theme';
}
