.PandemicGameGuidePage {
  .Content {
    a {
      color: $primaryColor;

      @include supportsHover {
        &:hover {
          color: lighten($primaryColor, 15);
        }
      }
    }
  }
}