.Link {
  color: $primaryColor;
  color: $link;
  cursor: pointer;
  text-decoration: none;
  strong {
    color: currentColor;
  }
  &:hover {
    color: $linkHover
  }
}
