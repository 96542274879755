// Root size
$rootFontSizeSm: 14px !default;
$rootFontSize: 16px !default;
$rootFontSizeLg: 18px !default;

// Typography
$size1: 300% !default;
$size2: 250% !default;
$size3: 200% !default;
$size4: 150% !default;
$size5: 125% !default;
$size6: 100% !default;
$size7: 75% !default;

$lineHeight: 1.3;
$fontFamily: sans-serif !default;
$weightLight: 300 !default;
$weightNormal: 400 !default;
$weightMedium: 700 !default;
$weightBold: 900 !default;

// Margins
$marginSm0: 0.125rem !default;
$marginSm1: 0.325rem !default;
$marginSm2: 0.5rem !default;
$marginSm3: 0.75rem !default;
$marginMd0: 1rem !default;
$marginMd1: 1.5rem !default;
$marginMd2: 2rem !default;
$marginMd3: 4rem !default;
$emSm0: 0.125em !default;
$emSm1: 0.325em !default;
$emSm2: 0.5em !default;
$emSm3: 0.75em !default;
$emMd0: 1em !default;
$emMd1: 1.5em !default;
$emMd2: 2em !default;
$emMd3: 4em !default;

// Radii
$radiusSm: 0.125rem !default;
$radius: 0.325rem !default;
$radiusMd: 1rem !default;
$radiusLg: 1.5rem !default;
$radiusRounded: 9999px !default;
$emRadiusSm: 0.125em !default;
$emRadius: 0.325em !default;
$emRadiusMd: 1em !default;
$emRadiusLg: 1.5em !default;

