.GameReview {
  color: $buttonColor;
  background-color: $bgColorSecondary;

  @if ($isDark: false) {
    .RouterLink {
      color: $buttonColor;
    }
  }

  &--negative .GameReview__Author .Icon > svg {
    filter: drop-shadow(2px 1px 0 $negativeReview);
  }

  &--positive .GameReview__Author .Icon > svg {
    filter: drop-shadow(2px 1px 0 $positiveReview);
  }

  &__Subject {
    color: darken($fontColor, 30);

    @if ($isDark: false) {
      color: $buttonColor;
      opacity: 0.65;
    }
  }
}
